import React from "react";
import { AnimatePresence } from "framer-motion";
import Signup from "./Signup/Signup";
import "./Auth.css";
import { useLocation, useRoutes } from "react-router-dom";
import Signin from "./Signin/Signin";
import iiitLogo from "../assets/iiit-logo.png";
import ResetPassword from "./ResetPassword/ResetPassword";
import NewPassword from "./NewPassword/NewPassword";
import Illustration from "./Illustrations.png";
import { Typography } from "@mui/material";
import FadeDiv from "../FadeDiv/FadeDiv";
import PageNotFound from "../../Pages/PageNotFound";

function Auth() {
	const location = useLocation();

	const routes = useRoutes([
		{ path: "sign-in", element: <Signin /> },
		{ path: "*", element: <PageNotFound /> },
		{ path: "sign-up", element: <Signup /> },
		{ path: "reset-password", element: <ResetPassword /> },
		{ path: "new-password", element: <NewPassword /> }
	]);

	return (
		<FadeDiv className="grid md:grid-cols-2 h-screen">
			<div className="flex flex-col h-screen w-full">
				<img src={iiitLogo} alt="IIIT Logo" className="w-48 mx-auto mt-16" />
				<div className="flex flex-1 items-center justify-center flex-col -mt-16">
					<div className="w-full">
						<AnimatePresence mode="wait">
							{routes && React.cloneElement(routes, { key: location.pathname })}
						</AnimatePresence>
					</div>
				</div>
			</div>
			<div className="auth-sidebar md:w-full md:flex hidden bg-p-blue items-center justify-center flex-col">
				<img src={Illustration} alt="Login Illustration" className="w-3/5" />
				<Typography
					variant="h3"
					className="text-white font-bold tracking-tight"
				>
					Remote Labs
				</Typography>
				<Typography
					variant="subtitle2"
					className="text-white opacity-80 max-w-md mt-2 text-center"
				>
					Perform science experiments in a real lab, remotely. Choose from a
					range of setups, including Vanishing Rod and Focal Length, and more upcoming ones.
				</Typography>
			</div>
		</FadeDiv>
	);
}

export default Auth;
