import React, { useEffect, useState } from "react";
// import axios from "axios";
import apiBaseURL from "../../utils/apiBaseURL";
import ax from "../../utils/ax";
import { Table } from "antd";
import Navbar from "../../components/Dashboard/Navbar/Navbar";
import PageNotFound from "../PageNotFound";
import { useRecoilValue } from "recoil";
import * as userStore from "../../recoil/atoms/user";
const Users = () => {
  const [users, setUsers] = useState([]);
  const user = useRecoilValue(userStore.state);

    useEffect(() => {
      ax.get(apiBaseURL + "/getAllUsers")
        .then((res) => res.data)
        .then((data) => {
          setUsers(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    }
  ];

  return (
    
      
      
      <>
			<Navbar />
		{
			user.isAdmin && (
				<div>
					<h1 className="text-center m-2">Users List</h1><Table columns={columns} dataSource={users} />
				</div>
			)
			
		}
		{
			!user.isAdmin && (
				<div>
					
					<PageNotFound />
				</div>
			)
		}
		</>
			
	);
    
};

export default Users;