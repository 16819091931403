import React, { useState } from "react";
import FadeDiv from "../../FadeDiv/FadeDiv";
import TextLink from "../../utils/TextLink/TextLink";
import { TextField, Alert, Typography } from "@mui/material";
import NormalCaseButton from "../../utils/NormalCaseButton/NormalCaseButton";
import isEmailValid from "../../../utils/validators/email";
import isPasswordValid from "../../../utils/validators/password";
import { Link, Navigate } from "react-router-dom";
import extractError from "../../../utils/extractError";
import * as userStore from "../../../recoil/atoms/user";
import { useRecoilState } from "recoil";
import apiBaseURL from "../../../utils/apiBaseURL";
import validationMessages from "../../../utils/validators/validationMessages";

function Signin() {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [user, setUser] = useRecoilState(userStore.state);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const signIn = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		userStore.methods
			.signin(email, password, setUser)
			.then(() => {
				console.log("Logged in!");
			})
			.catch((error) => {
				setError(extractError(error));
				console.error(error);
			})
			.then(() => setLoading(false));
	};

	if (user.email) return <Navigate to="/" />;

	return (
		<FadeDiv className="w-full flex" key="Signin">
			<div className="auth-frame-container mx-auto md:w-auto w-10/12 flex flex-col h-full justify-center">
				<Typography variant="h3" className="text-p-blue-dark">
					Login
				</Typography>
				<div className="mt-1">
					<Typography
						variant="subtitle2"
						className="text-p-blue-dark opacity-70"
					>
						Don't have an account yet?{" "}
						<Link to="../sign-up">
							<TextLink withoutLink>Sign up.</TextLink>
						</Link>
					</Typography>
				</div>

				{error && (
					<Alert severity="error" className="mt-4">
						Error: {error}
					</Alert>
				)}

				{/* Google Login Button */}
				<NormalCaseButton
					variant="contained"
					size="large"
					onClick={() => (window.location.href = `${apiBaseURL}/auth/google`)}
					className="bg-p-blue-dark text-white mt-8"
				>
					Login using Google
				</NormalCaseButton>

				{/* Partition line */}
				<div className="grid grid-cols-9 mt-4 mb-4">
					<div className="col-span-4 flex flex-col justify-center">
						<hr />
					</div>
					<span className="col-span-1 text-center text-slate-500 text-xs">
						OR
					</span>
					<div className="col-span-4 flex flex-col justify-center">
						<hr />
					</div>
				</div>

				{/* Password signin section */}
				<form className="grid gap-2" onSubmit={signIn}>
					<TextField
						label="Email Address"
						type="email"
						autoComplete="email"
						error={email != null && !isEmailValid(email)}
						helperText={
							email != null && !isEmailValid(email)
								? validationMessages.email
								: ""
						}
						onChange={handleEmailChange}
					/>
					<TextField
						label="Password"
						type="password"
						autoComplete="new-password"
						error={password != null && !isPasswordValid(password)}
						helperText={
							password != null && !isPasswordValid(password)
								? validationMessages.password
								: ""
						}
						onChange={handlePasswordChange}
					/>

					<Typography variant="caption" className="-mt-1 ml-0.5">
						<Link to="../reset-password">
							<TextLink withoutLink>Forgot your password?</TextLink>
						</Link>
					</Typography>

					<NormalCaseButton
						variant="contained"
						size="large"
						type="submit"
						disabled={
							!(isEmailValid(email) && isPasswordValid(password) && !loading)
						}
						className="mt-2"
					>
						Login with Email
					</NormalCaseButton>
				</form>
			</div>
		</FadeDiv>
	);
}

export default Signin;
