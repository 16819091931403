import React from "react";
import circuit from "./circuit.png"
import DEcircuit from "./DECircuit.png"

const ExperimentInstructions = {
	1: (
		<>
			<p>
				Use the Up and Down arrows in the control widget to control the position
				of the glass rods. You can do this multiple times to see the rod
				vanishing in the left beaker containing sunflower oil while it remains
				clearly visible in the right beaker containing water.
			</p>
			<p className="mt-4">
				You can click the Recalibrate button to reset the setup to its default
				state. After you are done with the experiment, click the 'Exit
				Experiment' button at the bottom of the right bar to exit the session.
				This will redirect you to the dashboard.
			</p>
		</>
	),
	2: (
		<>
			{/* <p>TODO</p> */}
			<p className="mt-4">
				You can click the Recalibrate button to reset the setup to its default
				state. After you are done with the experiment, click the 'Exit
				Experiment' button at the bottom of the right bar to exit the session.
				This will redirect you to the dashboard.
			</p>
		</>
	),
	0: (
		<>
			{/* <p>TODO</p> */}
			<p className="mt-4">
				You can click the Reset button to bring the ball to its default state.
				After you are done with the experiment, click the 'Exit Experiment'
				button at the bottom of the right bar to exit the session. This will
				redirect you to the dashboard.
			</p>
		</>
	),
	5: (
		<>
			<p className="mt-4">
				Use the Up and Down arrows in the control widget to control the position
				of the glass rods. You can do this multiple times to see the rod
				vanishing in the left beaker containing sunflower oil while it remains
				clearly visible in the right beaker containing water.<br></br>
			
				You can click the Recalibrate button to reset the setup to its default
				state. After you are done with the experiment, click the 'Exit
				Experiment' button at the bottom of the right bar to exit the session.
				This will redirect you to the dashboard.
			</p>
		</>
	),

	4: (
		<>
			<p className="mt-4">
			The experiment makes use of 4 modes to depict the working of resistances in series and parallel combinations.<br></br>

			Mode 1: A simple single resistor circuit that has voltage measured across its ends.<br></br>
				
			Mode 2: A series combination of two equivalent resistances to observe the voltage in between the resistances.<br></br>
				
			Mode 3: A circuit that helps understand the dynamics of a parallel combination of resistances.<br></br>
				
			Mode 4:	A circuit that constitutes of series and parallel combinations of resistances <br></br>
			
			Select the corresponding mode to observe measured values. After you are done with the experiment, click the 'Exit Experiment' button to exit the session.
			</p>
			<img
				src={circuit}
				style={{ transform: "scale(1.1)" }}
				alt="Experiment Illustration"
			/>
		</>
	),

	3: (
		<>
			<p className="mt-4">
			You will be performing acid-base titration. The aim of this experiment is to determine the concentration of a solution, given we know the contents of the solution. The particular acid used here is HCl and the base used is NaOH. Detection of the endpoint is aided by phenolphthalein.<br></br><br></br>

			To start of with the experiment, select the pour acid button, this will prepare our titrand (solution to be titrated). After this is completed, press the pour indicator button to empty a drop of phenolphthalein into the solution. Move to the next stage by clicking on the proceed button.<br></br><br></br>

			To start titrating, use any of the 3 volume options that will dispense the corresponding volume of base. The volume of the titrand, concentration of the base and volume of base dispensed should be visible on the right panel. <br></br><br></br>

			<b>Note:</b>: Start of with the higher volume buttons but slow down and use smaller volumes as you feel you are getting closer to the endpoint. <br></br><br></br>

			Look for hints of light pink, this will indicate that the endpoint has been reached. Proceed by clicking on the endpoint reached button. <br></br><br></br>

			Calculate the concentration of the solution using the concept of equating equivalents. <br></br><br></br>

			<math> M<sub>1</sub>V<sub>1</sub> = M<sub>2</sub>V<sub>2</sub> </math>
			<br></br>

			Enter this value in the text box provided and verify your results. <br></br><br></br>
			Proceed to exit the experiment.<br></br>
			</p>
		</>
	),
	6: (
		<>
			<p className="mt-4">
			You will be aiming to find out dielectric constant of the given materials through charging and discharging method. 
 
 			On choosing one of the three capacitors using the respective buttons, that particular capacitor will start charging(shown by the LED light). It charges for 50 seconds, taking readings every 5 seconds. It then starts discharging, for 50 seconds, and again takes readings every 5 seconds. Those reading are then stored in the table, shown when the “RESULT” button is clicked. Also a graph of charging and discharging v/s time is made by theses values. The Point at which both the lines intersect, gives the half time, which is then used to calculate the value of the dielectric constant of the material.
			</p>
			<img
				src={DEcircuit}
				style={{ transform: "scale(1.1)" }}
				alt="Experiment Illustration"
			/>
		</>
	)
};

export default ExperimentInstructions;
