import React from "react";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top"
		}
	}
};

const timeVals = [5, 10, 15, 20, 25, 30, 35];
const labels = [1, 2, 3, 4, 5, 6, 7];
// const fixedPE = [80.503, 72.765, 62.407, 50.997, 40.882];

// const velocityToKE = (v) => {
// 	return 0.5 * 0.1 * v * v * 1000;
// };

const roundToFour = (num) => {
	return Math.round(num * 10000) / 10000;
};

function Results({ open, handleClose, values }) {
	// const computed_ke = velocities.map(velocityToKE),
	let chargeVal = [];
	let DischargeVal = [];

	for(let i = 0; i< 7; i++){
		chargeVal.push(values[i]);
	}
	for(let i = 7; i< 14; i++){
		DischargeVal.push(values[i]);
	}

	const graphData = {
		labels,
		datasets: [
			{
				label: "Charging of Capacitor",
				data: chargeVal,
				borderColor: "rgb(79, 70, 229)"
			},
			{
				label: "Discharging of Capacitor",
				data: DischargeVal,
				borderColor: "rgb(16, 185, 129)"
			},
		]
	};

	const tableRows = [
		// {
		// 	point: "Starting Point",
		// 	pe: 83.356,
		// 	vel: 0,
		// 	ke: 0,
		// 	te: 83.356
		// }
	];

	const addTableRow = (time, chargingData, dischargingData) => {
		tableRows.push({
			time: time,
			cv: roundToFour(chargingData),
			dv: roundToFour(dischargingData),
		});
	};

	for (let i = 0; i < 7; i++)
		addTableRow(timeVals[i], chargeVal[i], DischargeVal[i]);

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
			<div className="flex flex-col items-center justify-center p-8">
				<Line options={options} data={graphData} />

				<TableContainer
					component={(props) => <Paper elevation={3} {...props} />}
					className="flex items-center justify-center mt-8 max-w-4xl"
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Time</TableCell>
								<TableCell align="right">Charging Data</TableCell>
								<TableCell align="right">Discharging Data</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									key={row.time}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.time}
									</TableCell>
									<TableCell align="right">{row.cv}</TableCell>
									<TableCell align="right">{row.dv}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Dialog>
	);
}

export default Results;
