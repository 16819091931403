import { atom } from "recoil";

const listState = atom({
	key: "listState", // unique ID (with respect to other atoms/selectors)
	default: {
		expId: "",
		experimentID: -1,
		name: "",
		description: "",
		theory: "",
		// status: "",
	}
});

export default listState;
