import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../CustomButton/CustomButton";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";
import Results from "./Results/Results";

const experimentID = 6;

function DielectricControls() {
	const [material, setMaterial] = useState("No Material Selected");
	const [exiting, setExiting] = useState(false);
	const [settingMaterial, setSettingMaterial] = useState(false);
	const [running, setRunning] = useState(false);
	const [values, setValues] = useState([]);
	const [resultsVisible, setResultsVisible] = useState(false);

	const navigate = useNavigate();
	const setCurrExp = useSetRecoilState(listState);

	const fetchExperimentResults = () => {
		// API call to fetch 5 velocities
		return ax
			.get(`${apiBaseURL}/experiments/dielectric/results`)
			.then((res) => res.data.results)
			.then((values) => {
				console.assert(values.length === 14);
				setValues(values);
				console.log("Values = " + values);
			});
	};

	// Keep track of the experiment's status, mark
	const checkRunning = () => {
		const checker = setInterval(() => {
			// API call to check if running
			return ax
				.get(`${apiBaseURL}/experiments/dielectric/status`)
				.then((res) => res.data.running)
				.then((running) => {
					if (!running) {
						clearInterval(checker);
						setRunning(false);
						fetchExperimentResults();
					}
				});
		}, 1000);
	};


	const updateMaterial = (selectedMaterial) => {
		setMaterial(selectedMaterial);
		setSettingMaterial(true);

		return ax
			.post(`${apiBaseURL}/experiments/dielectric/action`, {
				experimentID,
				params: { material: selectedMaterial }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setSettingMaterial(false));
	};

	const recalibrate = () => {
		return updateMaterial(material);
	};

	const exitExperiment = () => {
		setExiting(true);
        console.log("recalibrating Dielectric Constant");

		recalibrate()
			.then(() => {
				ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
					.then((res) => {
						if (!res.data.success) {
							alert(res.data.message || "An error occurred");
						}
					})
					.catch((res) => {
						alert(extractError(res));
						console.error(res);
					})
					.then(() => {
						setCurrExp({ experimentID: -1 });
						navigate("/");
					});
			})
			// .catch((err) => {
			// 	console.log("An error occurred while recalibrating before exit:", err);
			// });
	};

	const toggleResultsVisible = () => {
		setResultsVisible((resultsVisible) => !resultsVisible);
	};

	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);


	return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
                <div className="flex space-x-3">
						{/* <div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">
								{material === -1 ? "No Material Selected" : material}
							</h3>
							<p className="text-sm text-gray-500">Material of Capacitor</p>
                            <div className="mx-2"></div>
						</div> */}
					</div>
                    <CustomButton
                        onClick={() => updateMaterial(1)}
                        disabled={exiting || settingMaterial}
                    >
                        Capacitor 1
                    </CustomButton>
                    <CustomButton
                        onClick={() => updateMaterial(2)}
                        disabled={exiting || settingMaterial}
                    >
                        Capacitor 2
                    </CustomButton>
                    <CustomButton
                        onClick={() => updateMaterial(3)}
                        disabled={exiting || settingMaterial}
                    >
                        Capacitor 3
                    </CustomButton>
					<CustomButton
                        onClick={() => fetchExperimentResults()}
                        disabled={exiting || settingMaterial}
                    >
                        Get Results
                    </CustomButton>
                    <CustomButton
						className={`mt-8 ${values.length ? "" : "hidden"}`}
						onClick={toggleResultsVisible}
						disabled={exiting || running}
					>
						Show Results
					</CustomButton>
				</li>

				<li />
			</ul>

			<CustomButton
				type="danger"
				className="mt-3"
				disabled={exiting || settingMaterial}
				onClick={exitExperiment}
			>
				{exiting ? (
					<CircularProgress className="text-red-600" size={20} />
				) : (
					"Exit Experiment"
				)}
			</CustomButton>
			<Results
				open={resultsVisible}
				values={values}
				handleClose={toggleResultsVisible}
			/>
		</>
	);
}

export default DielectricControls;
