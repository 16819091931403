import React from "react";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top"
		}
	}
};

const labels = [1, 2, 3, 4, 5];
const fixedPE = [80.503, 72.765, 62.407, 50.997, 40.882];

const velocityToKE = (v) => {
	return 0.5 * 0.1 * v * v * 1000;
};

const roundToFour = (num) => {
	return Math.round(num * 10000) / 10000;
};

function Results({ open, handleClose, velocities }) {
	const computed_ke = velocities.map(velocityToKE),
		computed_total = computed_ke.map((ke, i) => ke + fixedPE[i]);

	const graphData = {
		labels,
		datasets: [
			{
				label: "Kinetic Energy",
				data: computed_ke,
				borderColor: "rgb(79, 70, 229)"
			},
			{
				label: "Potential Energy",
				data: fixedPE,
				borderColor: "rgb(16, 185, 129)"
			},
			{
				label: "Total Energy",
				data: computed_total,
				borderColor: "rgb(157, 23, 77)"
			}
		]
	};

	const tableRows = [
		{
			point: "Starting Point",
			pe: 83.356,
			vel: 0,
			ke: 0,
			te: 83.356
		}
	];

	const addTableRow = (pe, vel, ke, te) => {
		tableRows.push({
			point: `Point ${tableRows.length}`,
			pe: roundToFour(pe),
			vel: roundToFour(vel),
			ke: roundToFour(ke),
			te: roundToFour(te)
		});
	};

	for (let i = 0; i < 5; i++)
		addTableRow(fixedPE[i], velocities[i], computed_ke[i], computed_total[i]);

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
			<div className="flex flex-col items-center justify-center p-8">
				<Line options={options} data={graphData} />

				<TableContainer
					component={(props) => <Paper elevation={3} {...props} />}
					className="flex items-center justify-center mt-8 max-w-4xl"
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Points</TableCell>
								<TableCell align="right">Potential Energy (in mJ)</TableCell>
								<TableCell align="right">Velocity (in m/s)</TableCell>
								<TableCell align="right">Kinetic Energy (in mJ)</TableCell>
								<TableCell align="right">Total Energy (in mJ)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableRows.map((row) => (
								<TableRow
									key={row.point}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="th" scope="row">
										{row.point}
									</TableCell>
									<TableCell align="right">{row.pe}</TableCell>
									<TableCell align="right">{row.vel}</TableCell>
									<TableCell align="right">{row.ke}</TableCell>
									<TableCell align="right">{row.te}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Dialog>
	);
}

export default Results;
