import React from "react";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top"
		}
	}
};

function ResultsTitration({ open, expVals , baseDispensed, handleClose, UserVal}) {

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
			<div className="flex flex-col items-center justify-center p-8">

				<TableContainer
					component={(props) => <Paper elevation={3} {...props} />}
					className="flex items-center justify-center mt-8 max-w-4xl"
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Points</TableCell>
								<TableCell align="right">Initial Solution Volume</TableCell>
								<TableCell align="right">Conc. of Base</TableCell>
								<TableCell align="right">Conc. of Acid</TableCell>
								<TableCell align="right">Volume of Base dispensed</TableCell>
								<TableCell align="right">Your answer</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
                            <TableRow
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {"Values"}
                                </TableCell>
                                <TableCell align="right">{expVals[0]}</TableCell>
                                <TableCell align="right">{expVals[1]}</TableCell>
								<TableCell align="right">{expVals[2]}</TableCell>
                                <TableCell align="right">{baseDispensed}</TableCell>
                                <TableCell align="right">{UserVal}</TableCell>
                            </TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Dialog>
	);
}

export default ResultsTitration;
