import React from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Home from "./Home/Home";
import { useRecoilValue } from "recoil";
import * as userStore from "../../recoil/atoms/user";
import listState from "../../recoil/atoms/list";
import FadeDiv from "../FadeDiv/FadeDiv";
import { AnimatePresence } from "framer-motion";
import Experiment from "../Experiments/Experiment";
import Navbar from "./Navbar/Navbar";
import PageNotFound from "../../Pages/PageNotFound";

function Dashboard() {
	const user = useRecoilValue(userStore.state);
	const experimentData = useRecoilValue(listState);
	const location = useLocation();

	const routes = useRoutes([
		{
			path: "experiment",
			element: <Experiment experimentID={experimentData.experimentID} />
		},
		{ path: "", element: <Home /> },
		{ path: "/*", element: <PageNotFound /> },
	]);

	if (user.loaded && !user.email) {
		return <Navigate to="/auth/sign-in" />;
	}

	return (
		user.email && (
			<div className="flex h-full flex-col">
				<Navbar />
				<FadeDiv className="h-full">
					<AnimatePresence mode="wait">
						{routes && React.cloneElement(routes, { key: location.pathname })}
					</AnimatePresence>
				</FadeDiv>
			</div>
		)
	);
}

export default Dashboard;
