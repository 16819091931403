import React, { useState } from "react";
import { Alert, TextField, Typography } from "@mui/material";
import isEmailValid from "../../../utils/validators/email";
import NormalCaseButton from "../../utils/NormalCaseButton/NormalCaseButton";
import apiBaseURL from "../../../utils/apiBaseURL";
import extractError from "../../../utils/extractError";
import ax from "../../../utils/ax";
import { useNavigate } from "react-router-dom";
import validationMessages from "../../../utils/validators/validationMessages";

function ResetPassword() {
	const [email, setEmail] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const navigate = useNavigate();

	const sendOTP = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		ax.post(`${apiBaseURL}/auth/local/generate-password-reset-otp`, { email })
			.then((res) => {
				if (!res.data.success)
					setError(res.data.errors[0] || "An error occurred");
				else navigate("../new-password");
			})
			.catch((res) => {
				setError(extractError(res));
			})
			.then(() => setLoading(false));
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	return (
		<div className="w-full flex">
			<div className="auth-frame-container mx-auto md:w-auto w-10/12 flex flex-col h-full justify-center">
				<Typography variant="h3" className="text-p-blue-dark">
					Reset Password
				</Typography>
				<div className="mt-1 max-w-md">
					<Typography
						variant="subtitle2"
						className="text-p-blue-dark opacity-70"
					>
						Enter your registered email address. We'll send you an OTP to set a
						new password.
					</Typography>
				</div>

				{error && (
					<Alert severity="error" className="mt-4">
						Error: {error}
					</Alert>
				)}

				<form className="grid gap-2 mt-4" onSubmit={sendOTP}>
					<TextField
						label="Email Address"
						type="email"
						autoComplete="email"
						error={email != null && !isEmailValid(email)}
						helperText={
							email != null && !isEmailValid(email)
								? validationMessages.email
								: ""
						}
						onChange={handleEmailChange}
					/>

					<NormalCaseButton
						variant="contained"
						type="submit"
						size="large"
						disabled={!(isEmailValid(email) && !loading)}
					>
						Send OTP
					</NormalCaseButton>
				</form>
			</div>
		</div>
	);
}

export default ResetPassword;
