import React from "react";
import ExperimentInstructions from "./ExperimentInstructions";
import { useRecoilValue } from "recoil";
import listState from "../../../recoil/atoms/list";

function InfoSidebar({ experimentID }) {
	const experimentData = useRecoilValue(listState);

	return (
		<div className="bg-white xl:w-1/4 xl:flex-shrink-0 xl:border-r xl:border-gray-200">
			<div className="py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
				<div className="flex items-center justify-between">
					<div className="flex-1 space-y-8">
						<div className="space-y-8 sm:flex sm:items-center sm:justify-between sm:space-y-0 xl:block xl:space-y-8">
							<div className="-mb-2 flex items-center space-x-3">
								<div className="text-2xl font-medium text-gray-900">
									{experimentData.name}
								</div>
							</div>
							<div className="prose">
								{ExperimentInstructions[experimentID]}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default InfoSidebar;
