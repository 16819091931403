import { atom } from "recoil";

const streamListState = atom({
	key: "streamListState", // unique ID (with respect to other atoms/selectors)
	default: {
		expId: "",
		experimentID: -1,
		name: "",
		description: "",
		theory: "",
	}
});

export default streamListState;
