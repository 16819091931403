import React, { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import CustomButton from "../../../CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";
import Results from "./Results/Results";

const experimentID = 0;

function CoMEControls() {
	const [exiting, setExiting] = useState(false);
	const [resetting, setResetting] = useState(false);
	const [running, setRunning] = useState(false);
	const [velocities, setVelocities] = useState([]);
	const [resultsVisible, setResultsVisible] = useState(false);

	const navigate = useNavigate();
	const setCurrExp = useSetRecoilState(listState);

	const fetchExperimentResults = () => {
		// API call to fetch 5 velocities
		return ax
			.get(`${apiBaseURL}/experiments/come/results`)
			.then((res) => res.data.results)
			.then((velocities) => {
				console.assert(velocities.length === 5);
				setVelocities(velocities);
			});
	};

	// Keep track of the experiment's status, mark
	const checkRunning = () => {
		const checker = setInterval(() => {
			// API call to check if running
			return ax
				.get(`${apiBaseURL}/experiments/come/status`)
				.then((res) => res.data.running)
				.then((running) => {
					if (!running) {
						clearInterval(checker);
						setRunning(false);
						fetchExperimentResults();
					}
				});
		}, 1000);
	};

	const startExperiment = () => {
		setRunning(true);
		setVelocities([]);

		return ax
			.post(`${apiBaseURL}/experiments/come/action`, {
				experimentID,
				action: "start"
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
					setRunning(false);
				} else checkRunning();
			})
			.catch((res) => {
				alert(extractError(res));
				setRunning(false);
				console.error(res);
			});
	};

	const recalibrate = () => {
		setResetting(true);

		return ax
			.post(`${apiBaseURL}/experiments/come/action`, {
				experimentID,
				action: "reset"
			})
			.then((res) => {
				if (!res.data.success) alert(res.data.message || "An error occurred");
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setResetting(false));
	};

	const exitExperiment = () => {
		setExiting(true);

		recalibrate()
			.then(() => {
				ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
					.then((res) => {
						if (!res.data.success) {
							alert(res.data.message || "An error occurred");
						}
					})
					.catch((res) => {
						alert(extractError(res));
						console.error(res);
					})
					.then(() => {
						setCurrExp({ experimentID: -1 });
						navigate("/");
					});
			})
			.catch((err) => {
				console.log("An error occurred while recalibrating before exit:", err);
			});
	};

	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);

	const toggleResultsVisible = () => {
		setResultsVisible((resultsVisible) => !resultsVisible);
	};


	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);

	  
	return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">
								{running ? "Running" : "Idle"}
							</h3>
							<p className="text-sm text-gray-500">Experiment Status</p>
						</div>
					</div>
				</li>
				<li />
			</ul>

			<CustomButton
				type="primary"
				className="mt-8"
				onClick={startExperiment}
				disabled={exiting || running || resetting}
			>
				{running ? (
					<>
						<CircularProgress size={20} className="mr-2 text-white" /> Running
					</>
				) : (
					"Start Experiment"
				)}
			</CustomButton>

			<CustomButton
				className={`mt-8 ${velocities.length ? "" : "hidden"}`}
				onClick={toggleResultsVisible}
				disabled={exiting || running || resetting}
			>
				Show Results
			</CustomButton>

			<div className="flex gap-2">
				<CustomButton
					className="mt-3"
					onClick={recalibrate}
					disabled={exiting || running || resetting}
				>
					{resetting ? (
						<>
							<CircularProgress size={20} className="mr-2" />
							Resetting
						</>
					) : (
						"Reset"
					)}
				</CustomButton>
				<CustomButton
					type="danger"
					className="mt-3"
					disabled={exiting || running || resetting}
					onClick={exitExperiment}
				>
					{exiting ? (
						<CircularProgress className="text-red-600" size={20} />
					) : (
						"Exit Experiment"
					)}
				</CustomButton>
			</div>

			<Results
				open={resultsVisible}
				velocities={velocities}
				handleClose={toggleResultsVisible}
			/>
		</>
	);
}

export default CoMEControls;
