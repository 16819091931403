import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link,useLocation } from "react-router-dom";
import iiitLogoTree from "../../assets/iiit-logo-tree.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useRecoilState, useRecoilValue } from "recoil";
import * as userStore from "../../../recoil/atoms/user";
import { useSnackbar } from "notistack";
import listState from "../../../recoil/atoms/list";
import apiBaseURL from "../../../utils/apiBaseURL";
import { useNavigate, useParams } from "react-router-dom";
// import extractError from "../../utils/extractError";
import ax from "../../../utils/ax";
const navigation = [
	// { name: "Dashboard", href: "/", current: true },
	{
		name: "Feedback Form",
		href: "https://forms.office.com/r/MMCQzdxm4i",
		current: false
	}
	// { name: "Domains", href: "#", current: false }
];



function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}


function Navbar() {
	const location = useLocation();
	const [user, setUser] = useRecoilState(userStore.state);

	const navigate = useNavigate();
	const currExp = useRecoilValue(listState);
	const { enqueueSnackbar } = useSnackbar();

	const userNavigation = [
		{
			name: "Settings"
			// href: "#"
		},
		{
			name: "Sign out",
			onClick: () => {
				if (currExp.experimentID === -1) {
					navigate("/");
					userStore.methods.signout(setUser);
				} else {
					enqueueSnackbar("Please leave the running experiment session first", {
						variant: "error"
					});
				}
			}
		}
	];
	const adminNavigation = [
		{
			name: "Experiments",
			onClick:() =>{
				navigate("/admin/Experiment")
			},
		},
		{
			name: "Users",
			onClick:() =>{
				navigate("/admin/users")
			},
		},
		{
			name: "Settings"
			// href: "#"
		},
		{
			name: "Sign out",
			onClick: () => {
				if (currExp.experimentID === -1) {
					navigate("/");
					userStore.methods.signout(setUser);
				} else {
					enqueueSnackbar("Please leave the running experiment session first", {
						variant: "error"
					});
				}
			}
		},
		
	];
		const top = user?.isAdmin ? adminNavigation : userNavigation;

	return (
		<Disclosure as="nav" className="relative z-10 flex-shrink-0 bg-indigo-600">
			{({ open }) => (
				<>
					<div className="mx-auto px-2 sm:px-4 lg:px-8">
						<div className="relative flex h-16 items-center justify-between">
							{/* Logo section */}
							<Link to="/">
								<div className="flex items-center px-2 lg:px-0 xl:w-64">
									<div className="flex-shrink-0">
										<img
											className="h-8 w-auto"
											src={iiitLogoTree}
											style={{
												filter: "grayscale(1) invert(1) brightness(50000)"
											}}
											alt="Your Company"
										/>
									</div>
									<div className="rounded-md px-4 py-2 font-medium text-indigo-50 hover:text-white">
										<p className="text-sm">Remote Labs</p>
										<p className="text-xs">IIIT Hyderabad</p>
									</div>
								</div>
							</Link>
							{/* Links section */}
							<div className="lg:w-80">
								<div className="flex items-center justify-end">
									<div className="flex">
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className="rounded-md px-3 py-2 text-sm font-medium text-indigo-200 hover:text-white"
												aria-current={item.current ? "page" : undefined}
											>
												{item.name}
											</a>
										))}
									</div>
									{/* Profile dropdown */}
									<Menu as="div" className="relative ml-4 flex-shrink-0">
										<div>
											<Menu.Button className="flex rounded-full bg-indigo-700 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700">
												<span className="sr-only">Open user menu</span>
												{user.picture ? (
													<img
														src={user.picture}
														className="h-8 w-8 rounded-full"
														alt="Profile"
													/>
												) : (
													<AccountCircleIcon fontSize="large" />
												)}
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											{top.map((item) => (
													<Menu.Item key={item.name}>
														{({ active }) => (
															<button
																className={classNames(
																	active ? "bg-gray-100" : "",
																	"block w-full px-4 py-2 text-left text-sm text-gray-700"
																)}
																onClick={item.onClick}
															>
																{item.name}
															</button>
														)}
													</Menu.Item>
												))}
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Disclosure>
	);
}

export default Navbar;
