import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FocalLengthControls from "./FocalLengthControls/FocalLengthControls";
import VanishingRodControls from "./VanishingRodControls/VanishingRodControls";
import CoMEControls from "./CoMEControls/CoMEControls";
import KVLControls from "./KVLControls/KVLControls";
import TitrationControls from "./TitrationControls/TitrationControls";
import VanishingRodMiniControls from "./VanshingRodMiniControls/VanishingRodMiniControls";
import DielectricControls from "./DielectricControls/DielectricControls";
import { useSetRecoilState, useRecoilValue } from "recoil";
import listState from "../../../recoil/atoms/list";
import * as userStore from "../../../recoil/atoms/user";
import ax from "../../../utils/ax";
import apiBaseURL from "../../../utils/apiBaseURL";

const experimentControlsMapping = {
  0: CoMEControls,
  1: VanishingRodControls,
  2: FocalLengthControls,
  3: TitrationControls,
  4: KVLControls,
  5: VanishingRodMiniControls,
  6: DielectricControls
};

function CommandSidebar({ experimentID }) {
  const user = useRecoilValue(userStore.state);

  const ExperimentControls = experimentControlsMapping[experimentID];
  const navigate = useNavigate();

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const todayDate = getCurrentDate();
  const d = new Date();
  // const start = Date.now();
  // console.log(start);

  const currentMinute = d.getMinutes() % 10;
  const currentSeconds = d.getSeconds();
  let val = 600;

  const setCurrExp = useSetRecoilState(listState);
  const [seconds, setSeconds] = useState(val);

  const getTime = () => {
    return `${Math.floor(seconds / 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}:${(seconds - Math.floor(seconds / 60) * 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}`;
  };

  const handleTimer = () => {
    ax
      .post(apiBaseURL + "/expTimer", {
        userId: user._id,
        experimentID: experimentID,
        date: todayDate
      })
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          console.log("Full");
          val = 600;
        } else {
          val = 600 - currentMinute * 60 - currentSeconds;
        }

        setSeconds(val);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setCurrExp({ experimentID: -1 });
    }
  }, [seconds, navigate, setCurrExp]);

  return (
    <div className="w-1/4 bg-gray-50 pl-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200">
      <div className="relative mt-4 overflow-hidden rounded-lg border-2 border-indigo-200">
        <div
          className="absolute inset-0 h-full w-full origin-left bg-indigo-100"
          style={{ transform: `scaleX(${seconds / 600})` }}
        />
        <div
          className="relative w-full py-2.5 text-center text-lg tracking-wider"
          style={{
            fontFeatureSettings: "tnum",
            fontVariantNumeric: "tabular-nums"
          }}
        >
          {getTime()}
        </div>
      </div>

      <div className="pt-6 pb-2">
        <h2 className="text-sm font-semibold">Controls</h2>
      </div>

      <ExperimentControls />
    </div>
  );
}

export default CommandSidebar;
