import React, { useState, useEffect } from "react";
import Navbar from "../../components/Dashboard/Navbar/Navbar";
import { useRecoilValue } from "recoil";

import moment from "moment-timezone";
import { Calendar, message } from "antd";
import "./BookingPage.css"
import ax from "../../utils/ax";
import apiBaseURL from "../../utils/apiBaseURL";

import * as userStore from "../../recoil/atoms/user";
import bookingListState from "../../recoil/atoms/bookingList";
import { useNavigate } from "react-router-dom";


const BookingPage = (experimentID) => {
	const navigate = useNavigate();
	const currExp = useRecoilValue(bookingListState);


	const user = useRecoilValue(userStore.state);

	const [show, setShow] = useState(false);
	const [email, setEmail] = useState("");
	
	const [date, setDate] = useState("");
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
	const [bookedTimeSlotsDB, setbookedTimeSLotsDB] = useState([]);

	const handleSlots = () => {

		if(bookedTimeSlotsDB.date !== selectedDate ){
			ax.post(apiBaseURL + "/slotAvailability", {
			experimentId: currExp.expId,
          	date: date,
			})
			.then((response) => response.data)
			.then((data) => {
				setbookedTimeSLotsDB(data)
			})
			.catch((err) => {
				console.error(err);
			});
		}
	};
	const handleTimeSlotClick = (timeSlot) => {
		setSelectedTimeSlot(timeSlot);
	  };
	const getCurrentDate = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		return `${day}-${month}-${year}`;
	};
	const renderTimeSlots = () => {
		console.log(selectedDate);
		
		const timeSlots = [];
	
		const startTime = new Date(0); // 00:00
		const endTime = new Date(0); // 23:50
	
		// Set end time to 23:50
		startTime.setHours(0, 0, 0);
		endTime.setHours(23, 49, 0);
		if (selectedDate === getCurrentDate()) {
		  const currentTime = new Date();
		  const currentMinute = Math.ceil(currentTime.getMinutes() / 10) * 10; // Round up to nearest multiple of 10
		  startTime.setHours(currentTime.getHours(), currentMinute, 0);
		}
	
		// Generate time slots with 10-minute intervals
		while (startTime < endTime) {
		  const timeSlot = startTime.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		  });
		  
		  const bookedSlots = bookedTimeSlotsDB.bookedTimeSlots;
		//   console.log(bookedSlots)
		  if (!bookedSlots.includes(timeSlot)) {
			timeSlots.push(
			  <button
				key={timeSlot}
				onClick={() => handleTimeSlotClick(timeSlot)}
				className={selectedTimeSlot === timeSlot ? "selected" : ""}
			  >
				{timeSlot}
			  </button>
			);
		  }
	
		  startTime.setTime(startTime.getTime() + 10 * 60 * 1000);
		}
		
		return timeSlots;
		
	  };

	  const sendEmail = () => {
		ax.post(apiBaseURL + "/slotBookingMail", {
			userId: user._id,
			expId: currExp._id,
			expName: currExp.name,
			date: date,
			time: selectedTimeSlot,
		},
		{
			headers: {
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify({
				email,
			  }),
		})
		.then((response) => response.data)
		.then((data) => {
			setShow(true);
			setEmail("");
			console.log("Email sent")
		})
		.catch((err) => {
			console.error(err);
		});
	}

	const handleBooking = () => {
		ax.post(apiBaseURL + "/slotBooking", {
			userData: user,
			expData: currExp,
			date: date,
			time: selectedTimeSlot
		})
		.then((response) => response.data)
		.then((data) => {
			sendEmail();
			navigate("/")
			message.success(data)
		})
		.catch((err) => {
			console.error(err);
		});
	}
	
	useEffect(() => {
		if (currExp.experimentID === -1) navigate("/");
	}, [currExp, navigate]);

	
	return (
		currExp.experimentID !== -1 && (
		<>
			<Navbar />
			<div className="text-3xl font-bold text-center pt-2">{currExp.name}</div>
			<div className="flex flex-row justify-evenly delay-75">
			
				<div class="delay-15">
					<Calendar
					className="ant-calendar calendar-transition"
						fullscreen={false}
						disabledDate={(current) => {
							return current && current < moment().startOf("day");
						}}
						onSelect={(value) => {
							setSelectedDate(value.format("DD-MM-YYYY"));

							setDate(value.format("DD-MM-YYYY"));
							setSelectedTimeSlot(null);
							// handleSlots();
							// handleSlots();
						}}
						headerRender={false}
						onPanelChange={handleSlots()}
					/>
				</div>
			
			<div>
				<div className="tiles">
			{selectedDate && (
				<>
				<h4>Select a Time Slot:</h4>
				<div className="time-slots ">{renderTimeSlots()}</div>
				</>
			)}
			
			</div>
			
			</div>
			
			</div>
			{/* <p className="text">Selected Date and Time Slot: {selectedDate} at {selectedTimeSlot}</p> */}
			{selectedTimeSlot && <>
				<p className="text"></p>
				<p className="text">Selected Date and Time Slot: {selectedDate} at {selectedTimeSlot}</p>
				<div className="book-now">
								<button onClick={handleBooking}>Book Now</button>
							</div></>}
		</>
		)
	);
};

export default BookingPage;
