import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../CustomButton/CustomButton";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";
import Mode1 from "./Mode1.png";
import Mode2 from "./Mode2.png";
import Mode3 from "./Mode3.png";
import Mode4 from "./Mode4.png";

const ModeImg = {
	1: Mode1,
	2: Mode2,
	3: Mode3,
	4: Mode4
}

const experimentID = 4;

function KVLControls(){

    const [mode, setMode] = useState("No Mode Selected");
    const [exiting, setExiting] = useState(false);
    const [settingMode, setSettingMode] = useState(false);
    const navigate = useNavigate();
    const setCurrExp = useSetRecoilState(listState);

    const getV1 = () => {
        if(mode === 1){
            return "X";
        }else if(mode === 2){
            return "X";
        }else if(mode === 3){
            return "1.65 V";
        }else if(mode === 4){
            return "2.64 V";
        }else{
            return "X";
        }
    }

    const getV2 = () => {
        if(mode === 1){
            return "0 V";
        }else if(mode === 2){
            return "1.65 V";
        }else if(mode === 3){
            return "0 V";
        }else if(mode === 4){
            return "1.98 V";
        }else{
            return "X";
        }
    }

    const updateMode = (selectedMode) => {
        setMode(selectedMode);
        setSettingMode(true);

        return ax
			.post(`${apiBaseURL}/experiments/kvl/action`, {
				experimentID,
				params: { mode: selectedMode }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setSettingMode(false));
    }

    const recalibrate = () => {
		return updateMode(mode);
	};

    const exitExperiment = () => {
		setExiting(true);
		console.log("recalibrating KVL");

		recalibrate()		
		ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
				.then((res) => {
					console.log(res);
					if (!res.data.success) {
						alert(res.data.message || "An error occurred");
					}
				})
				.catch((res) => {
					alert(extractError(res));
					console.error(res);
				})
				.then(() => {
					setCurrExp({ experimentID: -1 });
					navigate("/");
				});
		// }
		// )
		// 	.catch((err) => {
		// 		console.log("An error occurred while recalibrating before exit:", err);
		// 	});
	};

	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);

    return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">
								{mode === -1 ? "No Mode Selected" : mode}
							</h3>
							<p className="text-sm text-gray-500">Mode of Operation</p>
                            <div className="mx-2"></div>
						</div>
					</div>
					<div className="flex gap-2">
						<CustomButton
							onClick={() => updateMode(1)}
							disabled={exiting || settingMode}
						>
							Mode 1
						</CustomButton>
						<CustomButton
							onClick={() => updateMode(2)}
							disabled={exiting || settingMode}
						>
							Mode 2
						</CustomButton>
                        <CustomButton
							onClick={() => updateMode(3)}
							disabled={exiting || settingMode}
						>
							Mode 3
						</CustomButton>
						<CustomButton
							onClick={() => updateMode(4)}
							disabled={exiting || settingMode}
						>
							Mode 4
						</CustomButton>
					</div>
				</li>

				<li />
			</ul>
            <li className="py-4">
                <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                        <h3 className="text-sm font-medium">{getV1()}</h3>
                        <p className="text-sm text-gray-500">Voltage at J1</p>
                    </div>
                </div>
            </li>
            <li className="py-4">
                <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                        <h3 className="text-sm font-medium">{getV2()}</h3>
                        <p className="text-sm text-gray-500">Voltage at J2</p>
                    </div>
                </div>
            </li>

			<CustomButton
				type="danger"
				className="mt-3"
				disabled={exiting || settingMode}
				onClick={exitExperiment}
			>
				{exiting ? (
					<CircularProgress className="text-red-600" size={20} />
				) : (
					"Exit Experiment"
				)}
			</CustomButton>
			{(mode !== "No Mode Selected" && mode !== 1) &&  <img
					src={ModeImg[mode]}
					style={{ transform: "scale(0.85)" }}
					alt="Experiment Mode Illustration"
			/>}
			{(mode !== "No Mode Selected" && mode === 1) &&  <img
					src={ModeImg[mode]}
					style={{ transform: "scale(0.7)" }}
					alt="Experiment Mode Illustration"
			/>}
		</>
	);

}

export default KVLControls;