import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../CustomButton/CustomButton";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";
import ResultsTitration from "./ResultsTitration/ResultsTitration";
import { TextField} from "@mui/material";
import NormalCaseButton from "./../../../utils/NormalCaseButton/NormalCaseButton";

const experimentID = 3;

function TitrationControls(){

	const [step, setStep] = useState(2);
	const [exiting, setExiting] = useState(false);
	// const [addingWater, setAddingWater] = useState(false);
	const [addingAcid, setAddingAcid] = useState(false);
	const [addingIndicator, setAddingIndicator] = useState(false);
	const [addingBase, setAddingBase] = useState(false);
	const [page, setPage] = useState(1);
	const navigate = useNavigate();
    const setCurrExp = useSetRecoilState(listState);
	const [userVal, settingUserVal] = useState(0);	
	const [ExpVals, setExpVals] = useState([]);
	const [resultsVisible, setResultsVisible] = useState(false);
	const [InitVolSol, setInitVolSol] = useState(0);
	const [ConcBase, setConcBase] = useState(0);
	const [UserBaseAmt, setUserBaseAmt] = useState(0.0);

    // const [mode, setMode] = useState("No Mode Selected");
    // const [exiting, setExiting] = useState(false);
    // const [settingMode, setSettingMode] = useState(false);
    

	const updateStep = (step) => {
		setStep(step);
	}

	const updatePage = (page) => {
		setPage(page);
	}

	const handleValChange = (event) => {
		settingUserVal(event.target.value)
	}

	const getExpVals = () => {
		return ax
			.get(`${apiBaseURL}/experiments/titration/results`)
			.then((res) => res.data.results)
			.then((expVals) => {
				console.assert(expVals.length === 3);
				setExpVals(expVals);
			});
	}

	const checkIndicatorStatus = () => {

		console.log("Thak gaya hun yaar, chal jaa plsss");

		return ax
			.get(`${apiBaseURL}/experiments/titration/indicator`)
			.then((res) => res.data)
			.then((response) => {
				if(response["v2"] == 0){
					updatePage(2); updateStep(5); setInitVolSol(response["v12"]); setConcBase(response["v13"]);
					console.log(response);
				}else{
					alert("Pouring of the Indicator is still in progress");
				}
			});
	}

    // const PourWater = () => {
    //     setAddingWater(true);

    //     return ax
	// 		.post(`${apiBaseURL}/experiments/titration/action`, {
	// 			experimentID,
	// 			params: { step : 1 }
	// 		})
	// 		.then((res) => {
	// 			if (!res.data.success) {
	// 				alert(res.data.message || "An error occurred");
	// 			}
	// 		})
	// 		.catch((res) => {
	// 			alert(extractError(res));
	// 			console.error(res);
	// 		})
	// 		.then(() => setAddingWater(false));
    // }

	const PourAcid = () => {
        setAddingAcid(true);

        return ax
			.post(`${apiBaseURL}/experiments/titration/action`, {
				experimentID,
				params: { step : 2 }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setAddingAcid(false));
    }

	const PourIndicator = () => {
        setAddingIndicator(true);

        return ax
			.post(`${apiBaseURL}/experiments/titration/action`, {
				experimentID,
				params: { step : 3 }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setAddingIndicator(false));
    }

	const PourBase = (base_quant) => {
        setAddingBase(true);
		setStep(5);

		if(base_quant === 1){
			setUserBaseAmt(UserBaseAmt + 3.0);
		}else if(base_quant === 2){
			setUserBaseAmt(UserBaseAmt + 1.0);
		}else if(base_quant === 3){
			setUserBaseAmt(UserBaseAmt + 0.5);
		}

        return ax
			.post(`${apiBaseURL}/experiments/titration/action`, {
				experimentID,
				params: { step : 4 , base : base_quant}
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setAddingBase(false));
    }

	const endPointReached = () =>{
		setStep(6);

		return ax
			.post(`${apiBaseURL}/experiments/titration/action`, {
				experimentID,
				params: { step : 5 }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
	}
	

    const recalibrate = () => {
		return updateStep(1);
	};

    const exitExperiment = () => {
		setExiting(true);
		console.log("recalibrating Titration");

		recalibrate()
		ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => {
				setCurrExp({ experimentID: -1 });
				navigate("/");
			});
			// })
			// .catch((err) => {
			// 	console.log("An error occurred while recalibrating before exit:", err);
			// });
	};

	window.addEventListener("unload", (event) => {
        exitExperiment();
    });

	const toggleResultsVisible = () => {
		setResultsVisible((resultsVisible) => !resultsVisible);
	};


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);

    return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							{/* <h3 className="text-sm font-medium">
								{mode === -1 ? "No Mode Selected" : mode}
							</h3> */}
							{/* <p className="text-sm text-gray-500">Mode of Operation</p> */}
                            <div className="mx-2"></div>
						</div>
					</div>
					{(page === 1) && 
					<>
					{/* <div className="flex gap-2">
							<CustomButton
								onClick={() => {updateStep(2); PourWater();}}
								disabled={exiting || (step !== 1)}
							>
								Pour Water
							</CustomButton>
					</div> */}
					<div className="flex gap-2">
							<CustomButton
								onClick={() => {updateStep(3); PourAcid();}}
								disabled={exiting || (step !== 2)}
							>
								Pour Acid
							</CustomButton>
					</div>
					<div className="flex gap-2">
							<CustomButton
								onClick={() => {updateStep(4); PourIndicator();}}
								disabled={exiting || (step !== 3)}
							>
								Add Indicator
							</CustomButton>
					</div>
					<CustomButton
						type="primary"
						className="mt-8"
						onClick={() => {checkIndicatorStatus()}}
						disabled={exiting || (step !== 4)}
					>
						Proceed
					</CustomButton>
					</>
					}
					{(page === 2) && 
					<>
						<li className="py-4">
							<div className="flex space-x-3">
								<div className="flex-1 space-y-1">
									<h3 className="text-sm font-medium">{InitVolSol} mL</h3>
									<p className="text-sm text-gray-500"> Initial Volume of Solution</p>
								</div>
							</div>
						</li>
						<li className="py-4">
							<div className="flex space-x-3">
								<div className="flex-1 space-y-1">
									<h3 className="text-sm font-medium">{ConcBase} moles/L</h3>
									<p className="text-sm text-gray-500"> Concentration of Base</p>
								</div>
							</div>
						</li>
						<p className="text-sm text-gray-500">Add Base</p>
						<div className="flex gap-2">
							<CustomButton
								onClick={() => {PourBase(1)}}
								// disabled={exiting || (step !== 5)}
							>
								3 ml
							</CustomButton>
							<CustomButton
								onClick={() => {PourBase(2)}}
								// disabled={exiting || (step !== 6)}
							>
								1 ml
							</CustomButton>
							<CustomButton
								onClick={() => {PourBase(3)}}
								// disabled={exiting || (step !== 7)}
							>
								0.5 ml
							</CustomButton>
						</div>
						<li className="py-4">
							<div className="flex space-x-3">
								<div className="flex-1 space-y-1">
									<h3 className="text-sm font-medium">{UserBaseAmt} mL</h3>
									<p className="text-sm text-gray-500"> Volume Of Base Dispensed</p>
								</div>
							</div>
						</li>
						<CustomButton
							type="primary"
							className="mt-8"
							onClick={() => {endPointReached()}}
							disabled={exiting || (step !== 5)}
						>
							End Point Reached
						</CustomButton>
						<br></br>
						<br></br>
						{(step === 6) && 
							<>
							<form className="grid gap-2">
								<TextField
								label="User Calculated Value of Acid Concentration in moles/L"
								onChange={handleValChange}
								/>
							</form>
							<NormalCaseButton
								variant="contained"
								size="large"
								type="submit"
								onClick={getExpVals}
								className="mt-2"
							>
								Submit Value
							</NormalCaseButton>
							</>
						}
					</>
					}
					
					{
					<CustomButton
					className={`mt-8 ${ExpVals.length ? "" : "hidden"}`}
					onClick={toggleResultsVisible}
					// disabled={exiting || running || resetting}
					>
						Show Results
					</CustomButton>
					}
				</li>

				<li />
			</ul>

            {/* <li className="py-4">
                <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                        <h3 className="text-sm font-medium">{getV1()}</h3>
                        <p className="text-sm text-gray-500">Voltage at J1</p>
                    </div>
                </div>
            </li>
            <li className="py-4">
                <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                        <h3 className="text-sm font-medium">{getV2()}</h3>
                        <p className="text-sm text-gray-500">Voltage at J2</p>
                    </div>
                </div>
            </li> */}

			<CustomButton
				type="danger"
				className="mt-3"
				disabled={exiting || addingAcid || addingIndicator || addingBase}
				onClick={exitExperiment}
			>
				{exiting ? (
					<CircularProgress className="text-red-600" size={20} />
				) : (
					"Exit Experiment"
				)}
			</CustomButton>

			<ResultsTitration
				open={resultsVisible}
				expVals={ExpVals}
				baseDispensed={UserBaseAmt}
				handleClose={toggleResultsVisible}
				UserVal = {userVal}
			/>
		</>
	);

}

export default TitrationControls;