import React, { useState } from "react";
import { Alert, TextField, Typography } from "@mui/material";
import NormalCaseButton from "../../utils/NormalCaseButton/NormalCaseButton";
import apiBaseURL from "../../../utils/apiBaseURL";
import extractError from "../../../utils/extractError";
import ax from "../../../utils/ax";
import { useNavigate } from "react-router-dom";
import isPasswordValid from "../../../utils/validators/password";
import isOTPValid from "../../../utils/validators/otp";
import validationMessages from "../../../utils/validators/validationMessages";

function ResetPassword() {
	const [otp, setOtp] = useState();
	const [newPassword, setNewPassword] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const navigate = useNavigate();

	const updatePassword = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		ax.post(`${apiBaseURL}/auth/local/reset-password`, { otp, newPassword })
			.then((res) => {
				if (!res.data.success)
					setError(res.data.error || res.data.errors[0] || "An error occurred");
				else {
					alert(
						"Your password has been updated, please sign in using your new password."
					);
					navigate("../sign-in");
				}
			})
			.catch((res) => {
				setError(extractError(res));
			})
			.then(() => setLoading(false));
	};

	return (
		<div className="w-full flex">
			<div className="auth-frame-container mx-auto md:w-auto w-10/12 flex flex-col h-full justify-center">
				<Typography variant="h3" className="text-p-blue-dark">
					Reset Password
				</Typography>
				<div className="mt-1 max-w-md">
					<Typography
						variant="subtitle2"
						className="text-p-blue-dark opacity-70"
					>
						What do you want to set your new password to? Enter it along with
						the OTP you received on your email address.
					</Typography>
				</div>

				{error && (
					<Alert severity="error" className="mt-4">
						Error: {error}
					</Alert>
				)}

				<form className="grid gap-2 mt-4" onSubmit={updatePassword}>
					<TextField
						label="New Password"
						type="password"
						autoComplete="password"
						error={newPassword != null && !isPasswordValid(newPassword)}
						helperText={
							newPassword != null && !isPasswordValid(newPassword)
								? validationMessages.password
								: ""
						}
						onChange={(event) => setNewPassword(event.target.value)}
					/>

					<TextField
						label="OTP"
						type="password"
						autoComplete="password"
						error={otp != null && !isOTPValid(otp)}
						helperText={
							otp != null && !isOTPValid(otp) ? validationMessages.otp : ""
						}
						onChange={(event) => setOtp(event.target.value)}
					/>

					<NormalCaseButton
						variant="contained"
						size="large"
						type="submit"
						disabled={
							!(isPasswordValid(newPassword) && isOTPValid(otp) && !loading)
						}
					>
						Reset Password OTP
					</NormalCaseButton>
				</form>
			</div>
		</div>
	);
}

export default ResetPassword;
