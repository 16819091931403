import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ax from "../../../../utils/ax";
import apiBaseURL from "../../../../utils/apiBaseURL";
import extractError from "../../../../utils/extractError";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../CustomButton/CustomButton";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";

const experimentID = 5;

function VanishingRodMiniControls() {
	const [direction, setDirection] = useState(0);
	const [exiting, setExiting] = useState(false);
	const [settingDir, setSettingDir] = useState(false);
	const navigate = useNavigate();
	const setCurrExp = useSetRecoilState(listState);

	const updateDirection = (dir) => {
		setDirection(dir);
		setSettingDir(true);

		return ax
			.post(`${apiBaseURL}/experiments/vrminiaction`, {
				experimentID,
				params: { direction: dir }
			})
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => setSettingDir(false));
	};

	const recalibrate = () => {
		return updateDirection(0);
	};

	const exitExperiment = () => {
		setExiting(true);
		console.log("recalibrating Titration");

		recalibrate()
		ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
			.then((res) => {
				if (!res.data.success) {
					alert(res.data.message || "An error occurred");
				}
			})
			.catch((res) => {
				alert(extractError(res));
				console.error(res);
			})
			.then(() => {
				setCurrExp({ experimentID: -1 });
				navigate("/");
			});
	};

	window.addEventListener("unload", (event) => {
        exitExperiment();
    });


	useEffect(() => {
		const handleTabClose = async (event) => {
		  event.preventDefault();
		  console.log('beforeunload event triggered');
		  await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		const handleBeforeUnload = async () => {
		  	await ax.post(`${apiBaseURL}/experiments/leave`, { experimentID })
		};
	  
		window.addEventListener('beforeunload', handleTabClose);
		window.addEventListener('unload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleTabClose);
		  window.removeEventListener('unload', handleBeforeUnload);
		};
	  }, []);


	return (
		<>
			<ul role="list" className="divide-y divide-gray-200">
				<li className="py-4">
					<div className="flex space-x-3">
						<div className="flex-1 space-y-1">
							<h3 className="text-sm font-medium">
								{direction ? "Immersed" : "Lifted Up"}
							</h3>
							<p className="text-sm text-gray-500">Rod Position</p>
							<div className="mx-2"></div>
						</div>
					</div>
					<div className="flex gap-2">
						<CustomButton
							onClick={() => updateDirection(0)}
							disabled={exiting || settingDir}
						>
							Move Up
						</CustomButton>
						<CustomButton
							onClick={() => updateDirection(1)}
							disabled={exiting || settingDir}
						>
							Move Down
						</CustomButton>
					</div>
				</li>

				<li />
			</ul>

			<CustomButton
				type="danger"
				className="mt-3"
				disabled={exiting || settingDir}
				onClick={exitExperiment}
			>
				{exiting ? (
					<CircularProgress className="text-red-600" size={20} />
				) : (
					"Exit Experiment"
				)}
			</CustomButton>
		</>
	);
}

export default VanishingRodMiniControls;
