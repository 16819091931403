import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import listState from "../../../../recoil/atoms/list";
import bookingListState from "../../../../recoil/atoms/bookingList";
import streamListState from "../../../../recoil/atoms/streamList";
import VRIllustration from "./illustrations/VR.svg";
import VRMiniIllustration from "./illustrations/VR_mini.svg";
import CoMEIllustration from "./illustrations/CoME.svg";
import MotorIllustration from "./illustrations/Motor.svg";
import FLIllustration from "./illustrations/FL.svg";
import DielectricConstant from "./illustrations/DielectricConstant.svg";
import { useNavigate } from "react-router-dom";
import KVLIllustration from "./illustrations/KVL.svg";
import TitrationIllustration from "./illustrations/Titration.svg"


const illustrationMapping = {
	"Vanishing Rod": VRIllustration,
	"Conservation of Mechanical Energy": CoMEIllustration,
	"PID Control of DC Motor": MotorIllustration,
	"Focal Length": FLIllustration,
	"Kirchhoff's Voltage Law" : KVLIllustration,
	"Titration" : TitrationIllustration,
	"Vanishing Rod Mini" : VRMiniIllustration,
	"Determination of Dielectric Constant" : DielectricConstant
};

function ExperimentCard({ info, className }) {
	const navigate = useNavigate();
	const setCurrExp = useSetRecoilState(listState);
	const setExp = useSetRecoilState(bookingListState);
	const setStreamExp = useSetRecoilState(streamListState);

	const updateCurrExp = () => {
		console.log(info);
		console.log(info);
		console.log("Start");
		if(info.status === 0){
			setCurrExp(info);
		}
		if(info.status === 1){
			alert(`The ${info.name} experiment is currently in use, please try again later`);
		}
	};

	const booking = () => {
		navigate("/user/BookingPage")
		setExp(info);
	};

	const watchStream = () => {
		navigate("/stream")
		setStreamExp(info);
	};
	
	
	return (
		<div className={`${className} mx-2 mb-4 w-90 text-white container`}>
			<div className="flex h-12 w-full justify-end pt-4 pr-4">
				{/* <CallMadeIcon /> */}
			</div>
			<div
				className="-mt-12 h-full bg-white bg-opacity-0 px-12 pt-24 pb-12 text-left transition hover:bg-opacity-10 relative"
			>
				<div className="my-10 ml-2 flex h-24 items-end">
					<img
						src={illustrationMapping[info.name]}
						style={{ transform: "scale(1.1)" }}
						alt="Experiment Illustration"
					/>
				</div>
				<div className="text-3xl font-bold ">{info.name}</div>
				<div className="mt-6 leading-6 opacity-80 pb-12 my-8 ">{info.description}
				{info.status === 1 && (
					<>
						<div className=" text-red-300 text-sm leading-4 mt-2  pt-2 border-t border-red-900 font-semibold" >This Experiment is currently performing by some other user. Kindly book a slot or You can watch the stream.</div>
						<button className="underline" onClick={watchStream}>Watch Stream</button>
						{/* <div className="buttons absolute inset-x-0 mt-1"> */}
						{/* <button type="submit" class="  text-white font-bold px-15 " onClick={updateCurrExp}>Watch Stream</button> */}
						{/* </div> */}
					</>
				)}
				</div>
				<div className="buttons absolute inset-x-0 bottom-0 flex flex-col pb-1">
					<button type="submit" id={info.name} class="border hover:bg-transparent hover:border-b-4 text-white font-bold py-2 px-4 my-2 " onClick={updateCurrExp}>Go to the Experiment</button>
					<button type="submit" class="border hover:bg-transparent hover:border-b-4 text-white font-bold py-2 px-4 my-2 " onClick={booking}>Book a Slot</button>
				</div>
			</div>
		</div>
		
		
	);
}

export default ExperimentCard;
