import React, { useEffect, useState } from "react";
import ax from "../../../utils/ax";
import Streams from "../../../../src/components/Experiments/Streams/Streams";
import FadeDiv from "../../../../src/components/FadeDiv/FadeDiv";
import { useRecoilValue } from "recoil";
import streamListState from "../../../recoil/atoms/streamList";
import { useNavigate } from "react-router-dom";
import apiBaseURL from "../../../utils/apiBaseURL";

function WatchStream() {
  const navigate = useNavigate();
  const currExp = useRecoilValue(streamListState);

  const [updStatus, setStatus] = useState(1);

  const backHome = () => {
    navigate("/");
  };

  const getExpUsagePin = () => {
    ax
      .post(apiBaseURL + "/checkPin", {
        experimentID: currExp.experimentID,
      })
      .then((response) => response.data)
      .then((data) => {
		console.log("data")
		console.log(data)
		if(data === 0) {
			console.log("updated")
			setStatus(0);
		}
        console.log("called");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (currExp.experimentID === -1) navigate("/");
  }, [currExp, navigate]);

  useEffect(() => {
    if (updStatus === 0) {
      navigate("/");
    }
  }, [updStatus, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      getExpUsagePin();
	  
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    currExp.experimentID !== -1 && (
      <FadeDiv className="h-full w-full">
        <div className="relative flex min-h-full flex-col">
          <h1 className="text-3xl font-bold content-center text-center ">
            Experiment: {currExp.name}
          </h1>
          <div>
            {updStatus === 0 && (
              <>
                <h1 className="text-3xl font-bold content-center text-center ">
                  The stream has ended!
                </h1>
              </>
            )}
          </div>

          <div className="mx-auto flex w-2/5 flex-grow justify-between lg:flex xl:px-8">
            <Streams experimentID={currExp.experimentID} />
          </div>
          <div className="content-center text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              onClick={backHome}
            >
              Back to Home
            </button>
          </div>
        </div>
      </FadeDiv>
    )
  );
}

export default WatchStream;