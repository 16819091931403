import React, { useEffect } from "react";
import ax from "../../utils/ax";
import InfoSidebar from "./InfoSidebar/InfoSidebar";
import Streams from "./Streams/Streams";
import CommandSidebar from "./CommandSidebar/CommandSidebar";
import FadeDiv from "../FadeDiv/FadeDiv";
import { useRecoilValue, useSetRecoilState } from "recoil";
import listState from "../../recoil/atoms/list";
import { useNavigate } from "react-router-dom";
import apiBaseURL from "../../utils/apiBaseURL";
import * as userStore from "../../recoil/atoms/user"
import { useState } from "react";
import { message } from "antd";

let rendered = 0;

function Experiment({ experimentID }) {
	const navigate = useNavigate();
	const currExp = useRecoilValue(listState);
	const setCurrExp = useSetRecoilState(listState);

	const user = useRecoilValue(userStore.state);

	const [state, setState] = useState(false);

	const getCurrentDate = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		return `${day}-${month}-${year}`;
	};

	const checkSlot = () => {
		console.log("getting into it")
		const todayDate = getCurrentDate();
		ax.post(apiBaseURL + "/checkUserSlot", {
			userId: user._id,
			experimentID: currExp.experimentID,
			date: todayDate
		})
		.then((res) => res.data)
		.then((data) => {
			if(data){
				setState(true)
			} else {
				setState(true)

				// setCurrExp({ experimentID: -1 })
				
				// if(rendered===1){
				// message.open({
				// 	type: 'warning',
				// 	content: "You have not booked any slot for the current time",
					
				//   });
				//   rendered=0;
				// } else {rendered++}
				
			}
		})
	}

	useEffect(() => {
		if (currExp.experimentID === -1 || state) navigate("/");
	}, [currExp, navigate]);

	useEffect(() => {
		checkSlot();
	}, [])

	return (
		currExp.experimentID !== -1 && state && (
			<FadeDiv className="h-full w-full">
				<div className="relative flex min-h-full flex-col">
					<div className="mx-auto flex w-full flex-grow justify-between lg:flex xl:px-8">
						{/* Left sidebar */}
						<InfoSidebar experimentID={experimentID} />

						<Streams experimentID={experimentID} />

						<CommandSidebar experimentID={experimentID} />
					</div>
				</div>
				{/* Right side background filler */}
				<div
					className="fixed top-0 right-0 h-full w-8 bg-gray-50"
					aria-hidden="true"
				/>
			</FadeDiv>
		)
	);
}

export default Experiment;
