import { atom } from "recoil";

const bookingListState = atom({
	key: "bookingListState", // unique ID (with respect to other atoms/selectors)
	default: {
		expId: "",
		experimentID: -1,
		name: "",
		description: "",
		theory: ""
	}
});

export default bookingListState;
