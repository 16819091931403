import React, { useEffect } from "react";
import FadeDiv from "../../FadeDiv/FadeDiv";
import ExperimentList from "./ExperimentList/ExperimentList";
import listState from "../../../recoil/atoms/list";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import * as userStore from "../../../recoil/atoms/user";
import PendingExperimentList from "./PendingExperimentList/PendingExperimentList";

function Home() {
	const navigate = useNavigate();
	const currExp = useRecoilValue(listState);
	const user = useRecoilValue(userStore.state);
	
	useEffect(() => {
		if (currExp.experimentID !== -1) navigate("experiment");
	}, [currExp, navigate]);
	
	return (
		currExp.experimentID === -1 && (
			<FadeDiv className="h-full">
				<div className="flex h-full flex-col bg-gray-50">
					<div className="relative">
						{/* Background Pattern SVG */}
						<div
							className="absolute inset-y-0 h-full w-full overflow-hidden"
							aria-hidden="true"
						>
							<div className="relative h-full">
								<svg
									className="absolute right-full translate-y-1/3 translate-x-1/4 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
									width={404}
									height={784}
									fill="none"
									viewBox="0 0 404 784"
								>
									<defs>
										<pattern
											id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
											x={0}
											y={0}
											width={20}
											height={20}
											patternUnits="userSpaceOnUse"
										>
											<rect
												x={0}
												y={0}
												width={4}
												height={4}
												className="text-gray-200"
												fill="currentColor"
											/>
										</pattern>
									</defs>
									<rect
										width={404}
										height={784}
										fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
									/>
								</svg>
								<svg
									className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
									width={404}
									height={784}
									fill="none"
									viewBox="0 0 404 784"
								>
									<defs>
										<pattern
											id="d2a68204-c383-44b1-b99f-42ccff4e5365"
											x={0}
											y={0}
											width={20}
											height={20}
											patternUnits="userSpaceOnUse"
										>
											<rect
												x={0}
												y={0}
												width={4}
												height={4}
												className="text-gray-200"
												fill="currentColor"
											/>
										</pattern>
									</defs>
									<rect
										width={404}
										height={784}
										fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
									/>
								</svg>
							</div>
						</div>
						
						{/* Title + Subheading */}
						<div className="relative pt-6 pb-16 sm:pb-24">
							<div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 sm:px-6">
								<div className="text-center">
									<h1
										className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
										<span className="block">Hi, {user.name}! Pick an </span>
										<span className="block text-indigo-600">Experiment</span>
									</h1>
									<p
										className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
										Perform science experiments in a real lab, remotely. Choose
										from a range of setups, including Vanishing Rod, Focal Length, Titration and more.
									</p>
								</div>
							</div>
						</div>
						
						<div className="relative">
							<div
								className="absolute inset-0 flex flex-col"
								aria-hidden="true"
							>
								<div className="flex-1" />
								<div className="w-full flex-1 bg-gray-800" />
							</div>
							<div className="mx-auto flex justify-center px-4 sm:px-6">
								<ExperimentList />
								{/* <PendingExperimentList/> */}
							</div>
						</div>
					</div>
					<div className="bg-gray-800" style={{ flex: 2 }} />
				</div>
			</FadeDiv>
		)
	);
}

export default Home;
