import { useEffect, useState } from "react";
import ax from "../../../utils/ax";
import apiBaseURL from "../../../utils/apiBaseURL";
import extractError from "../../../utils/extractError";
import { useSetRecoilState } from "recoil";
import listState from "../../../recoil/atoms/list";

const usePeer = (experimentID, setLoading) => {
	const [peer, setPeer] = useState();
	const [peerID, setPeerID] = useState(null);
	const [streams, setStreams] = useState([]);
	const setCurrExp = useSetRecoilState(listState);

	let configuration = {config: {iceServers: [
		{
		  urls: "stun:stun.relay.metered.ca:80",
		},
		{
		  urls: "turn:standard.relay.metered.ca:80",
		  username: "2e399e0a78b301912f915f4c",
		  credential: "205v6pP3+4ci8Va5",
		},
		{
		  urls: "turn:standard.relay.metered.ca:80?transport=tcp",
		  username: "2e399e0a78b301912f915f4c",
		  credential: "205v6pP3+4ci8Va5",
		},
		{
		  urls: "turn:standard.relay.metered.ca:443",
		  username: "2e399e0a78b301912f915f4c",
		  credential: "205v6pP3+4ci8Va5",
		},
		{
		  urls: "turns:standard.relay.metered.ca:443?transport=tcp",
		  username: "2e399e0a78b301912f915f4c",
		  credential: "205v6pP3+4ci8Va5",
		},
	]}}

	// Initialise peer
	useEffect(() => {
		setPeer(new window.Peer(configuration));
		console.log("Peer set");
	}, []);

	// Destroy peer on exit
	useEffect(() => {
		return () => {
			if (peer) peer.destroy();
		};
	}, []);

	// Request RasPi to call me
	useEffect(() => {
		if (peer) {
			peer.on("open", (id) => {
				setPeerID(id);

				ax.post(`${apiBaseURL}/experiments/join`, {
					experimentID,
					peerID: id
				})
					.then((res) => {
						if (!res.data.success) {
							// TODO: Uncomment this
							alert(res.data.message);
							setCurrExp({ experimentID: -1 });
						} else setLoading(false);
					})
					.catch((res) => {
						alert(extractError(res));
						console.error(res);
						setCurrExp({ experimentID: -1 });
					});
			});

			peer.off("call");

			// Handle all incoming calls

			peer.on("call", (call) => {
				call.answer();

				call.on("stream", (stream) => {
					// handle incoming stream
					setStreams((prevStreams) => [
						...prevStreams,
						{ peerId: call.peer, stream, close: call.close }
					]);
				});

				call.on("close", () => {
					// Handle call close
					setStreams((prevStreams) =>
						prevStreams.filter((s) => s.peerId !== call.peer)
					);
				});
			});
		}

		return () => {
			if (peer) {
				peer.off("open");
				peer.off("call");
			}
		};
	}, [peer]);

	return { peerId: peerID, streams };
};

export default usePeer;
