import React, { useState, useEffect } from "react";
import axios from "axios";
import apiBaseURL from "../../utils/apiBaseURL";
import ax from "../../utils/ax";
import { message, Table } from "antd";
import Navbar from "../../components/Dashboard/Navbar/Navbar";
import * as userStore from "../../recoil/atoms/user";

import listState from "../../recoil/atoms/list";
import { useRecoilValue } from "recoil";
import blynkToken from "../helpers/blynkTokens";
import PageNotFound from "../PageNotFound";

const Experiment = () => {
  const currExp = useRecoilValue(listState);
  const user = useRecoilValue(userStore.state);

	const [show, setShow] = useState(false);
	const [email, setEmail] = useState("");

	const [experiments, setExperiments] = useState([]);

	useEffect(() => {
		ax.get(apiBaseURL + "/getAllExperiments")
			.then((res) => res.data)
			.then((data) => {
				setExperiments(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);
	const getUser = () =>{
		console.log("User..........")
		console.log(user);
	}

	

	useEffect(() => {
		getUser();
		ax.get(apiBaseURL + "/exitCount")
			.then((res) => res.data)
			.then((data) => {
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const [status, setStatus] = useState([]);
	const [exitCount, setExitCount] = useState([]);

	const handleAccountStatus = (record, status) => {
		ax.post(apiBaseURL + "/changeAccountStatus", {
			expId: record.expId,
			status: status,
			data: record
		})
			.then((response) => response.data)
			.then((data) => {
				setStatus(data);
				window.location.reload();
			})

			.catch((error) => {
				console.log(error);
			});
		ax.post(apiBaseURL + "/mailController", {
			// data: record,
			expId: record.expId,
			expName: record.name
		})
		.then((response) => response.data)
		.then((data) => {
		})
		.catch((error) => {
			console.log(error);
		});
	};

	
	const columns = [
		{
			title: "Experiment",
			dataIndex: "name",
			render: (text, record) => <span>{record.name}</span>
		},
		{
			title: "Status",
			dataIndex: "status"
		},

		{
			title: "Actions",
			dataIndex: "actions",
			render: (text, record) => (
				<div className="d-flex">
					{record.status === "pending" ? (
						<button
							className="btn btn-success"
							onClick={() => handleAccountStatus(record, "approved")}
						>
							Activate
						</button>
					) : (
						<button
							className="btn btn-danger"
							onClick={() => handleAccountStatus(record, "pending")}
						>
							Block
						</button>
					)}
				</div>
			)
		},
		{
			title: "Exit Count",
			dataIndex: "exitCount",
		},
	];

	return (
		
		<>
			<Navbar />
		{
			user.isAdmin && (
				<div>
					<h1 className="m-3 text-center">Experiments List</h1>
					<Table columns={columns} dataSource={experiments} />
				</div>
			)
			
		}
		{
			!user.isAdmin && (
				<div>
					
					<PageNotFound />
				</div>
			)
		}
		</>
			
	);
};

export default Experiment;
