import React, { useRef, useState } from "react";
import usePeer from "./usePeer";
import { CircularProgress } from "@mui/material";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import modes from "./modes.png"


function Streams({ experimentID }) {
	const containerRef = useRef();

	const [loading, setLoading] = useState(true);
	const { streams } = usePeer(experimentID, setLoading);

	const dimensions = containerRef.current?.getBoundingClientRect();

	return (
		<div
			className="overflow-auto lg:min-w-0 lg:flex-1 bg-indigo-50/50"
			ref={containerRef}
		>
			<div className="mx-auto flex h-full w-full flex-col items-center justify-center">
				{/* {(experimentID === 4) && <img
					src={modes}
					style={{ transform: "scale(1.1)" }}
					alt="Experiment Mode Illustration"
				/>} */}
				{loading ? (
					<CircularProgress />
				) : (
					<div
						style={{ height: dimensions?.height }}
						className="relative flex w-full flex-col justify-around"
					>
						{streams.map(({ stream, peerId }) => (
							<VideoPlayer key={peerId} stream={stream} />
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default Streams;
