import React, { useEffect, useRef } from "react";

function VideoPlayer({ stream }) {
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.srcObject = stream;
			videoRef.current.play().catch((err) => {
				console.log("An error occurred during auto-play:", err);
			});
		}
	}, [stream]);

	return (
		<div className="relative flex h-full w-full flex-1 translate-x-0 items-center justify-center overflow-hidden">
			<video
				ref={videoRef}
				className="max-h-[95%] min-h-[95%] min-w-[95%] max-w-[95%] object-contain"
			/>
		</div>
	);
}

export default VideoPlayer;
